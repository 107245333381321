module services {
    export module applicationcore {
        export class aboutGTSService implements interfaces.applicationcore.IAboutGTSService {
            static $inject = ["$resource",
                "ENV",
                "$http",
                "generalService",
                "$timeout",
            ];

            constructor(private $resource: ng.resource.IResourceService,
                private ENV: interfaces.applicationcore.serverConfig,
                private $http: ng.IHttpService,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService
            ) {
            }

            getDetails(): ng.resource.IResourceClass<interfaces.applicationcore.IAboutGTS> {
                return this.$resource<interfaces.applicationcore.IAboutGTS>(this.ENV.DSP_URL + "AboutGTS/GetDetails", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }

            getManual(id: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'AboutGTS/GetManual?';
                
                url += 'manualId=' + id;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'manual.pdf';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }
        }
    }
    angular.module("app").service("aboutGTSService", services.applicationcore.aboutGTSService);
}